






import { defineComponent, onMounted } from '@vue/composition-api';
import { passportCheckLogoutState } from '@/passport/api'
import { useUserStore } from '@/pinia/modules/user'

export default defineComponent({
    name: 'PassportLogout',

    setup(props, { root }) {
        onMounted(async () => {
            const state = root.$route.query.state as string | undefined;
            const userStore = useUserStore()

            try {
                if (!state) {
                    throw new Error('No state received');
                }

                if (!passportCheckLogoutState(state)) {
                    throw new Error('Invalid state');
                }
                
                await userStore.logout()
            } catch (error) {
                console.error(`Authentication error: ${error}`)
            } finally {
                root.$router.push('/login')
            }
        })

        return {};
    },
});
